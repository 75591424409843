import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';

export const EditPCPPopover = styled(Popover, {
  zIndex: '2000 !important',
  '&.abyss-popover-trigger': {
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  '&.abyss-popover-title': {
    fontSize: '$xl !important',
    fontWeight: 'bold !important',
  },

  '&.abyss-popover-content-container': {
    overflow: 'hidden',
  },
});

export const EditPCPPopoverContainer = styled('div', {
  width: '10px',
  '> div': {
    zIndex: '10 !important',
  },
});

export const EditPCPPopoverButton = styled(Button, {
  '&.abyss-button-root': {
    paddingLeft: '0',
    '&:focus-visible': {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});
export const EditPCPPopoverButtonText = styled(Text, {
  paddingLeft: '$sm',
});

export const editPCPMenuStyle = {
  width: '72px',
  height: '18px',
  radius: '24px',
  border: 'none',
  backgroundColor: '#E5F8FB',
  padding: '0',
  '.abyss-badge-root': {
    padding: '0',
  },
  '.abyss-badge-content': {
    fontSize: '12.64px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '16px',
    color: '#196ECF',
  },
  '.abyss-icon-material': {
    marginRight: '4px',
  },
};

export const YourPCPHeaderMobile = styled('div', {
  width: '279px',
  height: '24px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '20.25px',
  lineHeight: '24px',
  color: '#323334',
  marginLeft: '24px',
});

export const PCPDrawerSection = styled('span', {
  height: '20px',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: '#196ECF',
  flex: 'none',
  order: '1',
  flexGrow: '0',
});

export const PCPDrawerMobile = {
  'abyss-modal-close-button': {
    marginTop: '8px',
    marginRight: '$lg',
  },
  'abyss-modal-header-container': {
    padding: '16px',
  },
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    height: 'auto !important',
  },
  'abyss-modal-content-title': {
    padding: 0,
  },
  'abyss-modal-body': {
    padding: '0 16px 16px',
  },
};
