import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Badge } from '@abyss/web/ui/Badge';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../store/usePCPStore';
import { PCPSearchStore } from '../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../AdobeTagging/adobeModalTrackEvent';
import { Constants } from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { LinkWithTracking } from '../../LinkWithTracking/LinkWithTracking';
import { Name } from '../../PSXHeader/ObapiDemo/memberProfile';
import { ThingsToConsiderModal } from '../../ThingsToConsiderModal';
import { convertObjectToUrlParams } from '../../Utils';
import { isCnsMember, isMnrMember } from '../../Utils/memberUtils/memberUtils';
import {
  editPCPMenuStyle,
  EditPCPPopover,
  EditPCPPopoverButton,
  EditPCPPopoverButtonText,
  EditPCPPopoverContainer,
  PCPDrawerMobile,
  PCPDrawerSection,
  YourPCPHeaderMobile,
} from './EditPCPMenu.styled';

export type EditPCPMenuProps = {
  showPcpLocation?: number;
  dependentSeqNbr?: string;
  name?: Name;
  providerID: string;
  providerType?: string;
  locationForAnalytics?: string;
  badge?: boolean;
  lineOfBusiness?: string;
  isPreEffective?: boolean;
};

export const EditPCPMenu = ({
  showPcpLocation = 0,
  providerID,
  dependentSeqNbr,
  name,
  providerType,
  locationForAnalytics,
  badge,
  lineOfBusiness,
  isPreEffective,
}: EditPCPMenuProps) => {
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDesktopModal, setIsOpenDesktopModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [choosePCP, setChoosePCP] = useState(false);
  const mobileScreen = useMediaQuery('(max-width: 463px)');
  const screenSize = mobileScreen ? window.innerHeight - 104 : 464;
  const changePCPButtonTestId: string = 'change-pcp-button';
  const changePCPLocationButtonTestId: string = 'change-pcp-location-button';
  const dependentPCPModalTestId: string = `yourPCP-${dependentSeqNbr}`;
  const yourPCPName = name
    ? `${name.firstName}'s PCP`
    : t('PRIMARY_CARE_PROVIDER.YOUR_PCP');

  const changeYourPcpName = name
    ? `Change ${name.firstName}'s PCP`
    : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP');

  const changeYourPcpLocationName = name
    ? `Change ${name.firstName}'s PCP location`
    : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP_LOCATION');

  const closeThingsToConsiderModal = () => {
    setShowModal(false);
  };

  const openThingsToConsiderModal = () => {
    setShowModal(true);
  };

  const handleContinue = () => {
    setShowModal(false);
    choosePCP ? handleChoosePCP() : handleChoosePCPLocations();
  };

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
      clearTypeaheadSearchStore: state.clearTypeaheadSearchStore,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      clearPCPSearchState: state.clearPCPSearchState,
    }))
  );
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
      clearAnalyticsState: state.clearAnalyticsState,
    }))
  );

  const handleChoosePCPLocations = () => {
    setAnalyticsState({
      originLinkNameForAnalytics: changePCPLocationButtonTestId,
    });
    const pcpStoreValues = {
      choosePCPId: providerID,
      dependentSeqNbr,
      selectedProviderType: providerType,
    };
    setPCPSearchState(pcpStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, pcpStoreValues);
    navigate(`${ConstantsRoutes.CHOOSE_PCP_LOCATION.path}${urlParams}`);
  };

  const handleChoosePCP = () => {
    setAnalyticsState({
      originLinkNameForAnalytics: changePCPLocationButtonTestId,
    });
    const typeaheadStoreValues = {
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      providerType: '',
    };
    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      resultType: Constants.RESULT_SECTION.ALL,
      acceptingNewPatients: true,
    });
    const pcpStoreValues = {
      choosePCP: true,
      dependentSeqNbr,
      pcpIndicator: true,
    };
    setPCPSearchState(pcpStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...typeaheadStoreValues,
      ...pcpStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const editIcon = (
    <IconMaterial
      css={{ marginLeft: '14px', width: '18px', height: '18px' }}
      icon="edit"
    />
  );

  const handleAdobeLinkTrackEvent = (
    linkTrackName: string,
    locationSection: string
  ) => {
    adobeLinkTrackEvent({
      destinationUrl: '',
      name: linkTrackName,
      location: `${locationSection}:${locationForAnalytics}`,
      type: 'internal',
    });
  };

  const handleLinkAndModalTrackEvents = (
    isOpenModalResponsive: boolean,
    isOpenModalDesktop: boolean
  ) => {
    const modalName = Constants.PRIMARY_CARE_PROVIDER.YOUR_PCP;
    if (isOpenModalResponsive || isOpenModalDesktop) {
      handleAdobeLinkTrackEvent(dependentPCPModalTestId, 'body');
      adobeModalTrackEvent(modalName);
    }
  };

  const showThingsToConsider = () =>
    (isMnrMember(lineOfBusiness) ? !isPreEffective : false) ||
    isCnsMember(lineOfBusiness);

  useEffect(() => {
    handleLinkAndModalTrackEvents(isOpen, isOpenDesktopModal);
  }, [isOpen, isOpenDesktopModal]);

  const popoverContent = (
    <React.Fragment>
      <Layout.Stack alignItems="left">
        <EditPCPPopoverButton
          data-auto-testid={changePCPButtonTestId}
          data-testid={changePCPButtonTestId}
          onClick={() => {
            handleAdobeLinkTrackEvent(changePCPButtonTestId, 'modal');
            if (showThingsToConsider()) {
              setChoosePCP(true);
              openThingsToConsiderModal();
            } else {
              handleChoosePCP();
            }
          }}
          variant="tertiary"
        >
          <IconMaterial icon="edit" />
          <EditPCPPopoverButtonText color="$interactive1">
            {name
              ? `Change ${name.firstName}'s PCP`
              : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP')}
          </EditPCPPopoverButtonText>
        </EditPCPPopoverButton>
        {showPcpLocation > 1 ? (
          <EditPCPPopoverButton
            data-auto-testid={changePCPLocationButtonTestId}
            data-testid={changePCPLocationButtonTestId}
            onClick={() => {
              handleAdobeLinkTrackEvent(changePCPLocationButtonTestId, 'modal');
              showThingsToConsider()
                ? openThingsToConsiderModal()
                : handleChoosePCPLocations();
            }}
            variant="tertiary"
          >
            <IconMaterial icon="edit" />
            <EditPCPPopoverButtonText color="$interactive1">
              {name
                ? `Change ${name.firstName}'s PCP location`
                : t('PRIMARY_CARE_PROVIDER.CHANGE_YOUR_PCP_LOCATION')}
            </EditPCPPopoverButtonText>
          </EditPCPPopoverButton>
        ) : null}
      </Layout.Stack>
    </React.Fragment>
  );

  const PopoverIconForMobileScreen = () =>
    badge ? (
      <Badge
        css={editPCPMenuStyle}
        data-auto-testid="edit-pcp-detail-drawer"
        data-testid="edit-pcp-detail-drawer"
        onClick={() => setIsOpen(true)}
      >
        <Flex css={{ flexWrap: 'nowrap' }}>
          <IconMaterial
            data-auto-testid="edit-pcp-detaile-header"
            data-testid="edit-pcp-detaile-header"
            icon="edit"
            onClick={() => setIsOpen(true)}
            size={16}
          />
          {t('PCP_BANNER.EDIT_PCP')}
        </Flex>
      </Badge>
    ) : (
      <IconMaterial
        data-auto-testid="edit-pcp"
        data-testid="edit-pcp"
        icon="more_vert"
        onClick={() => {
          setIsOpen(true);
        }}
      />
    );

  const PopoverIconForDesktopScreen = () =>
    badge ? (
      <Badge css={editPCPMenuStyle}>
        <Flex css={{ flexWrap: 'nowrap' }}>
          <IconMaterial
            data-auto-testid="vertIcon-detaile-header"
            data-testid="vertIcon-detaile-header"
            icon="edit"
            size={16}
          />
          {t('PCP_BANNER.EDIT_PCP')}
        </Flex>
      </Badge>
    ) : (
      <IconMaterial
        data-auto-testid="vertIcon"
        data-testid="vertIcon"
        icon="more_vert"
      />
    );

  return (
    <EditPCPPopoverContainer data-testid="edit-your-pcp">
      {mobileScreen ? (
        <React.Fragment>
          <PopoverIconForMobileScreen />
          <Drawer
            css={PCPDrawerMobile}
            data-auto-testid={dependentPCPModalTestId}
            data-testid={dependentPCPModalTestId}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            size={screenSize}
            title={
              <YourPCPHeaderMobile data-testid="your-pcp-title">
                {yourPCPName}
              </YourPCPHeaderMobile>
            }
          >
            <Drawer.Section>
              <Layout.Stack alignItems="left">
                <LinkWithTracking
                  analyticsInfo={{
                    location: `modal:mobile ${locationForAnalytics}`,
                  }}
                  before={editIcon}
                  css={{ marginBottom: '35px' }}
                  data-auto-testid="changePCP"
                  data-testid="changePCP"
                  isStandardAnchor
                  onClick={() => {
                    if (showThingsToConsider()) {
                      setChoosePCP(true);
                      openThingsToConsiderModal();
                    } else {
                      handleChoosePCP();
                    }
                  }}
                >
                  <PCPDrawerSection>{changeYourPcpName}</PCPDrawerSection>
                </LinkWithTracking>
                {showPcpLocation > 1 ? (
                  <LinkWithTracking
                    analyticsInfo={{
                      location: `modal:mobile ${locationForAnalytics}`,
                    }}
                    before={editIcon}
                    data-auto-testid="changePCPLocations"
                    data-testid="changePCPLocations"
                    isStandardAnchor
                    onClick={() => {
                      showThingsToConsider()
                        ? openThingsToConsiderModal()
                        : handleChoosePCPLocations();
                    }}
                  >
                    <PCPDrawerSection>
                      {changeYourPcpLocationName}
                    </PCPDrawerSection>
                  </LinkWithTracking>
                ) : null}
              </Layout.Stack>
            </Drawer.Section>
          </Drawer>
        </React.Fragment>
      ) : (
        <EditPCPPopover
          align="start"
          aria-label={t('PCP_BANNER.CHOOSE_PCP_OPTIONS')}
          content={popoverContent}
          css={{
            '&.abyss-popover-trigger': {
              height: '18px',
            },
          }}
          data-auto-testid={dependentPCPModalTestId}
          data-testid={dependentPCPModalTestId}
          onOpenChange={() => {
            setIsOpenDesktopModal(!isOpenDesktopModal);
          }}
          open={isOpenDesktopModal}
          position="bottom"
          showClose={false}
          title={<div data-testid="your-pcp-title">{yourPCPName}</div>}
          width="263"
        >
          {PopoverIconForDesktopScreen()}
        </EditPCPPopover>
      )}
      {showModal && (
        <ThingsToConsiderModal
          isOpen={showModal}
          onClose={closeThingsToConsiderModal}
          onContinue={handleContinue}
        />
      )}
    </EditPCPPopoverContainer>
  );
};
