import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { Constants } from '../../common/Constants';
import { getCategoryFromChipCategoriesCount } from '../../common/RecentActivity/helper';
import { getCurrentUTCYear } from '../../common/Utils/datesUtils/formatDate';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import {
  getCurrentMemberData,
  getPlanYear,
} from '../../utils/providerSearch.utils';
import { useCustomQuery } from '../useCustomQuery';
import GetRecentSearches from './RecentSearches.graphql';
import SaveRecentSearch from './SaveRecentSearch.graphql';

export interface RecentSearchesParams {
  psxSearchTerm: string;
  pesSearchTerm: string;
  searchType: string;
  category: string;
  location: string;
  latitude: string;
  longitude: string;
  coverageType: string;
  isMixedSearchSuggestion?: boolean;
  isUserTextSearch?: boolean;
  planYear: boolean;
  virtualCare?: boolean;
  gender?: string;
  conditions?: string;
  specialtyCategories?: string;
  bhProgramFacilityAgg?: string;
  aoeCodes?: string[];
}

export const usePlanYear = () => {
  const { dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const currentMember = getCurrentMemberData(dependentSeqNbr);
  const memberPlanYear = getPlanYear(currentMember);
  const currentYear = getCurrentUTCYear();

  return [
    currentMember?.isPreEffective || currentYear > 2024 ? memberPlanYear : null,
  ];
};

export const useGetRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GetRecentSearches, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'recentSearches',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useSaveRecentSearches = (options) => {
  const [recentSearchesData, setRecentSearchesData] = useState({});
  const [queryResult, queryAction] = useCustomQuery(SaveRecentSearch, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveRecentSearch',
    clearCache: [
      {
        key: 'RecentSearches',
        variables: ['planYear'],
      },
    ],
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setRecentSearchesData(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: recentSearchesData,
  };

  return [parsedResult, queryAction];
};

export const useGetRecentSearchesResults = () => {
  const [recentSearches, getRecentSearches] = useGetRecentSearches({});
  const isSuperUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.SUPER_USER) || false;
  const isGuestUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_GUEST) || false;
  const [planYear] = usePlanYear();

  useEffect(() => {
    if (!isSuperUser && !isGuestUser) {
      getRecentSearches({ variables: { planYear } });
    }
  }, [isSuperUser, planYear]);

  return recentSearches;
};

export const useSaveRecentSearchesResults = ({
  ...variables
}: RecentSearchesParams) => {
  const [saveRecentSearches, getSaveRecentSearches] = useSaveRecentSearches({});
  const [planYear] = usePlanYear();
  const isSuperUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.SUPER_USER) || false;
  const isGuestUser =
    storage.session.get(Constants.STORAGE_KEYS.SESSION.IS_GUEST) || false;

  const { pesSearchTerm, category } = variables;

  useEffect(() => {
    if (pesSearchTerm && category && !isSuperUser && !isGuestUser) {
      getSaveRecentSearches({
        variables: {
          ...variables,
          category: getCategoryFromChipCategoriesCount(category),
          coverageType: variables.coverageType || 'M',
          isMixedSearchSuggestion: variables.isMixedSearchSuggestion || false,
          isUserTextSearch: variables.isUserTextSearch || false,
          planYear,
        },
      });
    }
  }, [pesSearchTerm, isSuperUser, category, planYear]);

  return saveRecentSearches;
};
