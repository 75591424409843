import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-nested-ternary */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { SearchFilterContext } from '../../context/SearchFilterContext';
import { compareAndReturn } from '../../frontends/ProviderSearch/routes/SearchResults/utils';
/* eslint-disable @optum-fpc-psx-mobile-apps/no-missing-testid */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useLagoon } from '../../hooks/useLagoon';
import { useSnackcardViewAllCount } from '../../hooks/useSnackcardViewAllCount';
import { useSpacing } from '../../hooks/useSpacing';
import { Provider } from '../../models/Provider';
import { Option } from '../../models/ProviderSearch';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  CareCategories,
  Constants,
  FACILITIES_M1,
  FilterCollectionModel,
  ReverseCoverageTypesCodes,
  TypeaheadProviderTypes,
} from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly, resultsCarouselScreen } from '../ConstantsStyles';
import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { convertObjectToUrlParams } from '../Utils';
import { handleLinkAndModalTrack } from '../Utils/adobeTrackUtils';
import { MixedResultCards } from './MixedResultCards';
import {
  ButtonStyle,
  HeaderWrapper,
  HeadingContainer,
  InfoPopoverDrawerStyle,
  LinkWrapper,
  ListWrapper,
  NoResultsFoundContainer,
  ResultWrapper,
  headerTextStyle,
  viewAllTextStyle,
} from './ResultSection.style';

type Props = {
  acceptingNewPatients?: boolean;
  dependentSeqNbr?: string;
  headers: ResponseHeaders;
  results: Provider[] | null;
  totalCount: number;
  sectionType: string;
  search: string;
  userZip: string;
  specialtyCode: string;
  searchType: string;
  isLoading: boolean;
  preferredFacility: string[];
  providerType?: string;
  searchRadius: number;
  searchMethod: string;
  searchTerm: string;
  selectedChip: string;
  virtualCare?: boolean;
  llmFlag?: boolean;
  areaOfExpertise?: Option[];
  specialtyCodeFromQuery?: Option[];
  genderCode: Option[];
  virtualCareCode: Option;
};

export const ResultSection = ({
  acceptingNewPatients,
  dependentSeqNbr,
  headers,
  results,
  totalCount = 0,
  sectionType,
  search,
  userZip,
  specialtyCode,
  searchType,
  isLoading,
  preferredFacility,
  providerType,
  searchRadius,
  searchMethod,
  searchTerm,
  selectedChip,
  virtualCare,
  llmFlag,
  areaOfExpertise,
  specialtyCodeFromQuery,
  genderCode,
  virtualCareCode,
}: Props) => {
  const result = results?.slice(
    0,
    Constants.RESULT_SECTION.MAX_PROVIDERS_TO_DISPLAY_IN_MIXED_RESULTS_SCREEN
  );
  const { t } = useTranslation();
  const resultsBySectionType = `${sectionType} ${Constants.RESULT_SECTION.RESULTS}`;
  const { navigate } = useRouter();
  const mobileScreen = useMediaQuery(phoneOnly);
  const displayCarousel = useMediaQuery(resultsCarouselScreen);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [uesTranslation, setUEStranslation] = useState({});
  const resultSectionPopover: string = `result-section-${sectionType}-popover`;
  const resultsPopoverContent = useLagoon('mixed-results-popover')();
  const viewAllDataTestId = `results-section-${sectionType}-view-all`.replace(
    / /g,
    '-'
  );

  const { setDefaultSearchFilters } = useContext(SearchFilterContext);

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const {
    setTypeaheadSearchStore,
    combinedIncludeSpecialityRollupCodes = '',
    pseudoVerticals,
    suggestion,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
      combinedIncludeSpecialityRollupCodes:
        state.typeaheadSearchStore.combinedIncludeSpecialityRollupCodes,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      suggestion: state.typeaheadSearchStore.suggestion,
    }))
  );
  const { setSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      setSearchStore: state.setSearchStore,
    }))
  );
  const { setPCPSearchState, choosePCP = false } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );

  const { isExpandViewAllEnabled, viewAllCount, styleProps, css } =
    useSnackcardViewAllCount(totalCount);

  let popoverContent;
  let popoverTitle;

  switch (sectionType) {
    case Constants.RESULT_SECTION.PROVIDER:
      popoverContent = resultsPopoverContent[0]?.text;
      popoverTitle = resultsPopoverContent[0]?.headerTitle;
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      popoverContent = resultsPopoverContent[1]?.text;
      popoverTitle = resultsPopoverContent[1]?.headerTitle;
      break;
    case Constants.RESULT_SECTION.FACILITY:
      popoverContent = resultsPopoverContent[2]?.text;
      popoverTitle = resultsPopoverContent[2]?.headerTitle;
      break;
    default:
      popoverContent = 'Unable to retrieve information';
      popoverTitle = 'Unable to retrieve information';
  }

  const getCoverageTypes = (
    label,
    includeOrgTypeCodes,
    excludeOrgTypeCodes
  ): { includeOrgTypeCodes: string; excludeOrgTypeCodes: string } => {
    if (
      searchType === 'name' &&
      label === Constants.CHIPS_CATEGORIES.HEALTH_SUPPLIES
    ) {
      return {
        includeOrgTypeCodes: JSON.stringify(includeOrgTypeCodes),
        excludeOrgTypeCodes: '',
      };
    } else if (
      searchType === 'name' &&
      label === Constants.CHIPS_CATEGORIES.FACILITIES
    ) {
      return {
        includeOrgTypeCodes: '',
        excludeOrgTypeCodes: JSON.stringify(excludeOrgTypeCodes),
      };
    } else {
      return { includeOrgTypeCodes: '', excludeOrgTypeCodes: '' };
    }
  };

  useEffect(() => {
    const UESData = getModuleOrDefault('en', 'uesSpecialityTranslations');
    setUEStranslation(UESData);
  }, []);

  const getIncludeSpecialityRollupCodesFilteredByCategory = (
    codeByGroup,
    uesType
  ) => {
    const includeSpecialityRollupCodesFilteredByCategory: string[] = [];
    codeByGroup.forEach((value: string) => {
      const foundSpecialty = uesTranslation?.[value?.trim()];
      const isBHFacilityFound = foundSpecialty?.find(
        (specialty) =>
          specialty.type === TypeaheadProviderTypes.FACILITY &&
          specialty.coverageType ===
            ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']
      );
      if (isBHFacilityFound) {
        if (uesType === TypeaheadProviderTypes.BEHAVIORAL_HEALTH) {
          includeSpecialityRollupCodesFilteredByCategory.push(value);
        }
      }
      foundSpecialty?.forEach((specialty) => {
        if (uesType === specialty?.type) {
          includeSpecialityRollupCodesFilteredByCategory.push(value);
        }
      });
    });
    return includeSpecialityRollupCodesFilteredByCategory;
  };

  const handleViewAllOnClick = () => {
    let includeSpecialityRollupCodesFilteredByCategory: string[] = [];
    let categoryCoverageType: string | undefined;
    let orgTypeCodes;
    if (!choosePCP) {
      const chipObject = Constants.CARE_CATEGORIES_NAME_PAYLOAD.find(
        (category) => category.label === selectedChip
      );
      const codeByGroup =
        combinedIncludeSpecialityRollupCodes?.split(',') || [];
      categoryCoverageType = chipObject?.coverages?.[0];

      includeSpecialityRollupCodesFilteredByCategory =
        getIncludeSpecialityRollupCodesFilteredByCategory(
          codeByGroup,
          chipObject?.uesType
        );

      orgTypeCodes = getCoverageTypes(
        chipObject?.label,
        chipObject?.includeOrgTypeCodes,
        FACILITIES_M1
      );
    }

    const defaultFilters: FilterCollectionModel[] = [
      FilterCollectionModel.AUTO_SEARCH_RADIUS,
    ];
    if (acceptingNewPatients) {
      defaultFilters.push(FilterCollectionModel.ACCEPTING_NEW_PATIENTS);
    }
    if (acceptingNewPatients) {
      defaultFilters.push(FilterCollectionModel.ACCEPTING_NEW_PATIENTS);
    }
    if (virtualCare) {
      defaultFilters.push(FilterCollectionModel.VIRTUAL_CARE);
    }
    if (preferredFacility !== undefined && preferredFacility.length > 0) {
      defaultFilters.push(FilterCollectionModel.PREFERRED_FACILITY);
    }

    if (areaOfExpertise && areaOfExpertise.length > 0) {
      defaultFilters.push(FilterCollectionModel.AREA_OF_EXPERTISE);
    }
    if (specialtyCodeFromQuery && specialtyCodeFromQuery.length > 0) {
      defaultFilters.push(FilterCollectionModel.SPECIALTY_CODE);
    }
    if (genderCode.length > 0) {
      defaultFilters.push(FilterCollectionModel.GENDER_CODE);
    }
    if (virtualCareCode) {
      defaultFilters.push(FilterCollectionModel.VIRTUAL_CARE);
    }

    setDefaultSearchFilters(defaultFilters, {
      autoSearchRadius: searchRadius,
      preferredFacility,
      AreaOfExpertise: areaOfExpertise,
      SpecialtyCode: specialtyCodeFromQuery,
      GenderCode: genderCode,
      VirtualCare: virtualCareCode,
    });
    setAnalyticsState({ linkName: viewAllDataTestId });
    setTypeaheadSearchStore({
      specialtyCode,
      search,
      sectionType,
      searchType,
      providerType,
      includeSpecialityRollupCodes:
        searchType === 'name'
          ? ''
          : includeSpecialityRollupCodesFilteredByCategory.join(','),
      acceptingNewPatients,
      searchMethod,
      searchTerm: compareAndReturn(searchTerm, search),
      ...(categoryCoverageType && {
        coverageType: categoryCoverageType,
      }),
      userZip,
      llmFlag,
    });
    setSearchStore({
      totalCount,
      includeOrgTypeCodes: orgTypeCodes?.includeOrgTypeCodes,
      excludeOrgTypeCodes: orgTypeCodes?.excludeOrgTypeCodes,
      searchRadius,
    });
    setPCPSearchState({
      pcpIndicator: compareAndReturn(
        choosePCP,
        selectedChip === CareCategories.PRIMARY_CARE
      ),
      dependentSeqNbr,
    });

    const urlParams = convertObjectToUrlParams(chipStore, {
      providerType,
      search,
      searchRadius,
      sectionType,
      searchType,
      specialtyCode,
      pseudoVerticals,
      suggestion,
    });
    navigate(
      `${ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path}${urlParams}`
    );
  };

  useEffect(() => {
    if (isOpenPopover) {
      const linkName: string = resultSectionPopover;
      const linkLocation: string = `body:${sectionType}`;
      const modalName: string =
        typeof sectionType !== 'string' ? String(sectionType) : sectionType;
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [isOpenPopover]);

  const spacing = useSpacing(sectionType !== 'Provider');

  return (
    <React.Fragment>
      {isLoading || results?.length ? (
        <Layout.Stack css={spacing} grow space={0}>
          <HeaderWrapper>
            <ResultWrapper>
              <HeadingContainer>
                <Heading
                  css={headerTextStyle}
                  data-auto-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  data-testid={`results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  display="h6"
                  offset={2}
                >
                  {t(resultsBySectionType)}
                </Heading>
                {!mobileScreen ? (
                  <Popover
                    aria-label={`Information about ${t(sectionType)} ${t(
                      'results'
                    )}`}
                    content={popoverContent}
                    css={{
                      '.abyss-popover-title': {
                        marginRight: '2px',
                      },
                      'abyss-popover-trigger': {
                        display: 'flex',
                        position: 'relative',
                        top: '2px',
                      },
                    }}
                    data-auto-testid="result-section-popover-icon"
                    data-testid="result-section-popover-icon"
                    onOpenChange={() => setIsOpenPopover(!isOpenPopover)}
                    open={isOpenPopover}
                    title={popoverTitle || t(resultsBySectionType)}
                  >
                    <IconMaterial
                      data-auto-testid="result-section-popover-icon"
                      data-testid="result-section-popover-icon"
                      icon="info"
                      onClick={() => setIsOpenPopover(!isOpenPopover)}
                      size={16}
                      variant="outlined"
                    />
                  </Popover>
                ) : (
                  <Button
                    css={ButtonStyle}
                    data-auto-testid="mobile-view-info-drawer-icon"
                    data-testid="mobile-view-info-drawer-icon"
                    onClick={() => setIsOpen(true)}
                    rounded
                    size="$xs"
                    variant="ghost"
                  >
                    <IconMaterial icon="info" size="$sm" variant="outlined" />
                  </Button>
                )}
              </HeadingContainer>
              <Drawer
                css={InfoPopoverDrawerStyle}
                data-auto-testid="mobile-view-info-drawer"
                data-testid="mobile-view-info-drawer"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                position="bottom"
                size="$xs"
                title={
                  <Heading color="$gray8" offset={4}>
                    {popoverTitle || t(resultsBySectionType)}
                  </Heading>
                }
              >
                {popoverContent}
              </Drawer>
            </ResultWrapper>
            {result?.length ? (
              <LinkWrapper>
                <LinkWithTracking
                  after={
                    <IconMaterial
                      icon="chevron_right"
                      size={(isExpandViewAllEnabled && '$md') || '$xs'}
                    />
                  }
                  analyticsInfo={{
                    location: `body:${sectionType} results`,
                  }}
                  css={css}
                  data-auto-testid={viewAllDataTestId}
                  data-testid={viewAllDataTestId}
                  isStandardAnchor
                  onClick={handleViewAllOnClick}
                >
                  <Text
                    color="$interactive1"
                    {...styleProps}
                    css={(!isExpandViewAllEnabled && viewAllTextStyle) || {}}
                  >
                    {`${t('View all')} ${viewAllCount}`}
                  </Text>
                </LinkWithTracking>
              </LinkWrapper>
            ) : null}
          </HeaderWrapper>
          <ListWrapper displayCarousel={displayCarousel}>
            <MixedResultCards
              handleViewAllOnClick={handleViewAllOnClick}
              headers={headers}
              isLoading={isLoading}
              results={results}
              searchTerm={searchTerm}
              sectionType={sectionType}
            />
          </ListWrapper>
        </Layout.Stack>
      ) : null}
      {!isLoading && !results ? (
        <Layout.Stack css={{ marginBottom: '$md' }} grow space={0}>
          <HeaderWrapper>
            <ResultWrapper>
              <Layout.Group>
                <Heading
                  css={headerTextStyle}
                  data-auto-testid={`no-results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  data-testid={`no-results-section-header-${sectionType}`.replace(
                    / /g,
                    '-'
                  )}
                  display="h6"
                  offset={2}
                >
                  {t(resultsBySectionType)}
                </Heading>
                {!mobileScreen ? (
                  <Popover
                    aria-label={`Information about ${t(sectionType)} ${t(
                      'results'
                    )}`}
                    content={popoverContent}
                    css={{
                      '.abyss-popover-title': {
                        marginRight: '2px',
                      },
                    }}
                    data-auto-testid="result-section-popover-icon"
                    data-testid="result-section-popover-icon"
                    onOpenChange={() => setIsOpenPopover(!isOpenPopover)}
                    open={isOpenPopover}
                    title={popoverTitle || t(resultsBySectionType)}
                  >
                    <IconMaterial
                      data-auto-testid="result-section-popover-icon"
                      data-testid="result-section-popover-icon"
                      icon="info"
                      onClick={() => setIsOpenPopover(!isOpenPopover)}
                      size={16}
                      variant="outlined"
                    />
                  </Popover>
                ) : (
                  <Button
                    data-auto-testid="mobile-view-info-drawer-icon"
                    data-testid="mobile-view-info-drawer-icon"
                    onClick={() => setIsOpen(true)}
                    rounded
                    size="$xs"
                    variant="ghost"
                  >
                    <IconMaterial icon="info" size="$sm" variant="outlined" />
                  </Button>
                )}
              </Layout.Group>
            </ResultWrapper>
          </HeaderWrapper>
          <NoResultsFoundContainer>
            <Text>{t('No Results Found')}</Text>
          </NoResultsFoundContainer>
        </Layout.Stack>
      ) : null}
    </React.Fragment>
  );
};
