import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Alert } from '@abyss/web/ui/Alert';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import find from 'lodash/find';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useAdobeBrowserBackButtonTrack } from '../../hooks/adobeHook/useAdobeBrowserBackButtonTrack';
import { useCarePath } from '../../hooks/useCarePath/useCarePath';
import { useCoverageType } from '../../hooks/useCoverageType';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useLagoon } from '../../hooks/useLagoon';
import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import { useSaveRecentSearchesResults } from '../../hooks/useRecentSearches';
import { CarePathsResponse } from '../../models/CarePaths';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../models/FacilityDetails';
import { CostCareCategory } from '../../models/Lagoon';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../models/ProviderDetails';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import {
  formatProviderStartDate,
  isProviderStartDateInFuture,
} from '../../utils/date.utils';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../utils/featureSuppress';
import {
  getFacilityDetailTabList,
  getProviderDetailTabList,
  getProviderPCPId,
} from '../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../utils/providerNames.utils';
import { getShowCost } from '../../utils/showCost.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCurrentMember,
  getCurrentMemberPCPInfo,
  getLoggedInMember,
  getNetworkIdsForPES,
} from '../../utils/user.utils';
import {
  Constants,
  LOB,
  RecentSearchesSearchTypes,
  ReverseCoverageTypesCodes,
} from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { AlertStyling } from '../DetailsPageHeader/DetailsPageHeader.styled';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';
import { FacilityDetailsAboutSection } from '../FacilityDetailsTabs/FacilityDetailsAboutSection';
import { FacilityLocation } from '../FacilityDetailsTabs/FacilityLocation';
import { FacilityQuality } from '../FacilityDetailsTabs/FacilityQuality/FacilityQuality';
import { ProviderGroupAbout } from '../ProviderGroupDetailTabs/ProviderGroupAbout';
import { getGeoLocationFromStorage } from '../PSXHeader/SearchBar/utils';
import { convertObjectToUrlParams, getFeatureFlag } from '../Utils';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { translatedTabTitle } from '../Utils/translatedTabTitle.util';
import { About } from './About/About';
import { Cost } from './Cost/Cost';
import { PhysicianDirectory } from './PhysicianDirectory';
import {
  ChooseAsPCPButton,
  Container,
  TabContainer,
  TabsContainer,
  TerminationWrapper,
} from './ProviderDetailTabs.styled';
import { ProviderLocationsDetails } from './ProviderLocationsDetails/ProviderLocationsDetails';
import { Quality } from './Quality/Quality';

type Props = {
  providerDetails: ProviderDetails | FacilityDetails;
  chipValue: string;
  navigateToDirections: boolean;
  setNavigateToDirections: (a: boolean) => void;
  isTiered?: boolean;
  isMapView?: boolean;
  coverageType: string;
  locationKey?: string;
  setIsMapView: (a: boolean) => void;
  handleTabChange: (a: number) => void;
  active?: number;
};

export const ProviderDetailTabs = ({
  providerDetails,
  navigateToDirections,
  chipValue,
  coverageType,
  isTiered,
  isMapView = false,
  locationKey,
  setNavigateToDirections,
  setIsMapView,
  handleTabChange,
  active,
}: Props) => {
  const [enableCostTab] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_TAB_ENABLED,
  ]);

  const uspToggleFlag = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE,
  ]);

  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { adobeBrowserBackButtonTrack } = useAdobeBrowserBackButtonTrack();
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const { portalData } = useContext(PortalContext);
  const portalShowCost = getShowCost(portalData);
  const {
    providerId,
    providerLocations = [],
    providerName,
    providerType,
    organizationCode,
  } = providerDetails;

  const [displayAlert, setDisplayAlert] = useState(false);

  const { longitude, latitude, name: location } = getGeoLocationFromStorage();
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const findSelectedLocationIndex = (
    locations: ProviderLocation[] | FacilityLocationsResponse[]
  ) => locations.findIndex((loc) => selectedId === loc.locationId);

  const foundIndex = findSelectedLocationIndex(providerLocations);
  const selectedIndex = foundIndex > -1 ? foundIndex : 0;
  const selectedLocation: ProviderLocation | FacilityLocationsResponse =
    providerLocations[selectedIndex];

  const { BEHAVIORAL_HEALTH_TYPECODES } = Constants.PROVIDER_DETAILS;
  const isBehavioralHealthProvider =
    BEHAVIORAL_HEALTH_TYPECODES.includes(coverageType);

  const isVisionCare = coverageType === ReverseCoverageTypesCodes.VISION;
  const isDentalCare = coverageType === ReverseCoverageTypesCodes.DENTAL;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();
  const currentMember = getCurrentMember();
  const lob = currentMember?.lineOfBusiness;
  const locale = getLanguage().code;
  const costCareCategories: CostCareCategory[] = useLagoon(
    'cost-care-categories'
  )();
  const costCategoriesSortOrder = costCareCategories?.find((sortOrder) =>
    sortOrder?.locale?.toLowerCase().includes(locale.toLowerCase())
  )?.DNT_CostCategory;
  const { storeLocationId } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      storeLocationId: state.detailsState.locationId,
    }))
  );

  const locationId = locationKey ? locationKey : storeLocationId;

  const [, setHasCarePathData] = useState<boolean>(false);
  const [carePathData, setCarePathData] = useState<CarePathsResponse>({});
  const [carePathPageNum, setCarePathPageNum] = useState<number>(1);
  const [carePathStepCategory, setCarePathStepCategory] = useState<string>('');

  const coverageTypeData = useCoverageType();
  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const providerCoverageType = coverageType ? coverageType : coverageTypeData;
  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    providerCoverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const [carePaths, getCarePaths] = useCarePath({});
  const { carePath } = carePaths?.data || {};

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;

  const coverageTypeCode = getClaimOrEligibilitySystemTypeCode(
    currentMember,
    coverageType
  );

  const { claimSystemTypeCode, eligibilitySystemTypeCode } = coverageTypeCode;
  const isMnr = isMnrMember(lob);

  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );

  const { data } = usePrimaryCare();
  const { primaryCare } = data;
  const loggedInMemberInfo = getLoggedInMember();
  const memberPCPId = getCurrentMemberPCPInfo(
    currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  )?.providerID;

  const checkPCP = (providerPCP, memberPCP) => providerPCP === memberPCP;

  const providerPCPId = getProviderPCPId(
    providerLocations as ProviderLocation[],
    providerId,
    memberPCPId
  );
  const lagoonData = useLagoon('ui-messaging')();

  const inNetworkBanner = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.IN_NETWORK_BANNER_FUTURE_START_DATE,
  });
  const displayInNetworkBadgeEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_IN_NETWORK_BADGE_FUTURE_START_DATE
  );

  const displayInNetworkBadge = isProviderStartDateInFuture(
    selectedLocation?.network?.start
  );

  const { navigate } = useRouter();

  const { choosePCP: choosePCPFlag, setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { chipValue: value } = chipStore;

  const handleChoosePCPClick = () => {
    const typeaheadStoreValues = {
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      providerType: '',
    };
    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      resultType: Constants.RESULT_SECTION.ALL,
      acceptingNewPatients: true,
    });
    const pcpStoreValues = {
      choosePCP: true,
      dependentSeqNbr: loggedInMemberInfo?.demographics?.dependentSeqNbr,
      pcpIndicator: true,
    };
    setPCPSearchState(pcpStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...typeaheadStoreValues,
      ...pcpStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const handleChoosePCPLocationClick = (event) => {
    const pcpStoreValues = {
      choosePCPId: event.currentTarget.dataset.providerid,
      selectedProviderType: providerType,
      choosePCP: choosePCPFlag,
    };
    setPCPSearchState(pcpStoreValues);

    const urlParams = convertObjectToUrlParams(chipStore, {
      ...pcpStoreValues,
      value,
      coverageType,
    });
    navigate(`${ConstantsRoutes.CHOOSE_PCP_LOCATION.path}${urlParams}`);
  };

  useEffect(() => {
    if (providerType === ProviderType.PRACTITIONER && !isMnr) {
      getCarePaths({
        variables: {
          coverageType: providerCoverageType,
          carePathStepCategory,
          claimSystemTypeCode,
          eligibilitySystemTypeCode,
          lob,
          membershipCategory,
          providerLocationKey: locationId,
          providerType,
          pageSize: 10,
          pageNumber: carePathPageNum,
          reciprocityId,
          sortOrder: costCategoriesSortOrder,
          locale,
        },
      });
    }
  }, [providerId, locationId, carePathPageNum, carePathStepCategory]);

  useEffect(() => {
    if (carePath?.totalCount > 0) {
      setHasCarePathData(true);
      setCarePathData(carePath);
    } else {
      setCarePathData({});
    }
  }, [carePath]);

  useEffect(() => {
    adobeBrowserBackButtonTrack();
  }, []);

  let recentSearchArgs = {
    psxSearchTerm: '',
    pesSearchTerm: '',
    pseudoVerticals: '',
    searchType: '',
    category: chipValue,
    location,
    latitude,
    longitude,
    coverageType,
  };

  const boardCertifications = (providerDetails as ProviderDetails)
    .boardCertifications;

  const providerTabs = getProviderDetailTabList(
    boardCertifications,
    providerLocations,
    isVisionCare,
    isDentalCare
  );
  const facilityTabs = getFacilityDetailTabList(
    isVisionCare,
    (providerDetails as FacilityDetails).organizationCode
  );

  if (providerType === ProviderType.PRACTITIONER) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: parseProviderName(
        providerName,
        providerType,
        (providerDetails as ProviderDetails).primaryDegrees?.[0],
        getNameAttrs(providerDetails)
      ),
      pesSearchTerm: providerId,
      searchType: RecentSearchesSearchTypes.PRACTITIONER_DETAIL,
    };
  } else if (providerType === ProviderType.ORGANIZATION) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: providerName,
      pesSearchTerm: providerId,
      searchType: RecentSearchesSearchTypes.ORGANIZATION_DETAIL,
    };
  } else if (providerType == ProviderType.MEDICAL_GROUP) {
    recentSearchArgs = {
      ...recentSearchArgs,
      psxSearchTerm: providerName,
      pesSearchTerm: providerId,
      searchType: RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL,
    };
  }

  const activeTabs: string[] = [];

  useSaveRecentSearchesResults(recentSearchArgs);

  const renderDisclaimer = () => {
    if (currentMember?.lineOfBusiness === LOB.CNS) {
      return (
        <DisclaimersContainer
          isVisionCare={isVisionCare}
          showPremiumCareDisclaimer={!isBehavioralHealthProvider}
        />
      );
    }

    if (
      currentMember?.lineOfBusiness === LOB.MNR ||
      currentMember?.lineOfBusiness === LOB.ENI
    ) {
      return isVisionCare ? (
        <DisclaimersContainer
          isLandingPage
          isVisionCare={isVisionCare}
          showPremiumCareDisclaimer={!isBehavioralHealthProvider}
        />
      ) : null;
    }

    return null;
  };

  const renderProviderTabs =
    providerType === ProviderType.PRACTITIONER
      ? providerTabs.map((tabTitle: string) => {
          if (
            tabTitle === Constants.PROVIDER_DETAILS.COST &&
            (!enableCostTab || suppressCostEstimates || !portalShowCost)
          )
            return null;
          activeTabs.push(tabTitle);
          return (
            <Tabs.Tab
              data-auto-testid={tabTitle}
              data-testid={tabTitle}
              key={tabTitle}
              label={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
              tabbableContent={false}
              value={t(translatedTabTitle('PROVIDER_DETAILS', tabTitle))}
            >
              <TabContainer>
                {tabTitle === Constants.PROVIDER_DETAILS.ABOUT && (
                  <About
                    coverageType={coverageType}
                    locationKey={locationKey}
                    providerDetails={providerDetails as ProviderDetails}
                    suppressReviews={suppressReviews}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                  <ProviderLocationsDetails
                    isMapView={isMapView}
                    isTiered={isTiered}
                    navigateToDirections={navigateToDirections}
                    providerDetails={providerDetails as ProviderDetails}
                    setIsMapView={setIsMapView}
                    setNavigateToDirections={setNavigateToDirections}
                    setUpdateLocation={setDisplayAlert}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle === Constants.PROVIDER_DETAILS.COST && (
                  <Cost
                    carePathsData={carePathData}
                    providerDetails={providerDetails as ProviderDetails}
                    setCarePathPageNum={setCarePathPageNum}
                    setCarePathStepCategory={setCarePathStepCategory}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle === Constants.PROVIDER_DETAILS.QUALITY && (
                  <Quality
                    boardCertifications={boardCertifications}
                    coverageType={coverageType}
                    organizationCode={organizationCode}
                    providerName={providerName}
                    providerType={providerType}
                    selectedLocation={selectedLocation as ProviderLocation}
                    tabTitle={tabTitle}
                  />
                )}
                <DisclaimersContainer
                  isVisionCare={isVisionCare}
                  showPremiumCareDisclaimer={!isBehavioralHealthProvider}
                />
              </TabContainer>
            </Tabs.Tab>
          );
        })
      : null;

  const renderFacilityTabs =
    providerType === ProviderType.ORGANIZATION
      ? facilityTabs.map((tabTitle: string) => {
          activeTabs.push(tabTitle);
          return (
            <Tabs.Tab
              data-auto-testid={tabTitle}
              data-testid={tabTitle}
              key={tabTitle}
              label={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
              tabbableContent={false}
              value={t(translatedTabTitle('FACILITY_DETAILS', tabTitle))}
            >
              <TabContainer>
                {tabTitle === Constants.FACILITY_DETAILS.ABOUT && (
                  <FacilityDetailsAboutSection
                    coverageType={coverageType}
                    facilityDetails={providerDetails as FacilityDetails}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle === Constants.FACILITY_DETAILS.LOCATIONS && (
                  <FacilityLocation
                    coverageType={coverageType}
                    facilityDetails={providerDetails as FacilityDetails}
                    isMapView={isMapView}
                    isTiered={isTiered}
                    navigateToDirections={navigateToDirections}
                    setIsMapView={setIsMapView}
                    setNavigateToDirections={setNavigateToDirections}
                    setUpdateLocation={setDisplayAlert}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle ===
                  Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                  <PhysicianDirectory
                    facilityLocations={
                      providerLocations as FacilityLocationsResponse[]
                    }
                    providerId={providerId}
                    providerType={providerType}
                  />
                )}
                {tabTitle === Constants.FACILITY_DETAILS.QUALITY && (
                  <FacilityQuality
                    organizationCode={organizationCode}
                    providerName={providerName}
                    providerType={providerType}
                    selectedLocation={
                      selectedLocation as FacilityLocationsResponse
                    }
                    tabTitle={tabTitle}
                  />
                )}
                {renderDisclaimer()}
              </TabContainer>
            </Tabs.Tab>
          );
        })
      : null;

  const renderProviderGroupTabs =
    providerType === ProviderType.MEDICAL_GROUP
      ? Constants.PROVIDER_GROUP_DETAILS.TABS_LIST.map((tabTitle: string) => {
          activeTabs.push(tabTitle);
          return (
            <Tabs.Tab
              data-auto-testid={tabTitle}
              data-testid={tabTitle}
              key={tabTitle}
              label={t(translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle))}
              tabbableContent={false}
              value={t(translatedTabTitle('PROVIDER_GROUP_DETAILS', tabTitle))}
            >
              <TabContainer>
                {tabTitle === Constants.PROVIDER_GROUP_DETAILS.ABOUT && (
                  <ProviderGroupAbout
                    coverageType={coverageType}
                    locationKey={locationKey}
                    providerDetails={providerDetails}
                    tabTitle={tabTitle}
                  />
                )}

                {tabTitle === Constants.PROVIDER_DETAILS.LOCATIONS && (
                  <ProviderLocationsDetails
                    isMapView={isMapView}
                    navigateToDirections={navigateToDirections}
                    providerDetails={providerDetails as ProviderDetails}
                    setIsMapView={setIsMapView}
                    setNavigateToDirections={setNavigateToDirections}
                    setUpdateLocation={setDisplayAlert}
                    tabTitle={tabTitle}
                  />
                )}
                {tabTitle ===
                  Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY && (
                  <PhysicianDirectory
                    providerGroupLocations={
                      providerLocations as ProviderLocation[]
                    }
                    providerId={providerId}
                    providerType={providerType}
                  />
                )}
                {renderDisclaimer()}
              </TabContainer>
            </Tabs.Tab>
          );
        })
      : null;

  const renderTerminationBanner = (selectedLocation) => {
    const formattedEndDate = getFormattedDate(selectedLocation?.network?.end);
    const isPCP = checkPCP(providerPCPId, memberPCPId);
    const displayFutureTerminationDate = checkTerminationDate(
      displayTerminationDateEnabled,
      formattedEndDate
    );

    let locationsNotTerminated = false;
    providerLocations?.forEach((loc) => {
      const terminationDates = checkTerminationDate(
        displayTerminationDateEnabled,
        loc.network?.end
      );
      if (!terminationDates) {
        locationsNotTerminated = true;
      }
    });

    return (
      displayFutureTerminationDate && (
        <TerminationWrapper
          cssProps={{ isPCP }}
          data-testid="termination-message"
        >
          <Alert
            title={
              isPCP ? (
                <>
                  <div>
                    {t(
                      'PCP_TERMINATION.YOUR_PCP_MAY_BE_LEAVING_THE_NETWORK_ON'
                    )}{' '}
                    ({formattedEndDate})
                  </div>
                  <ChooseAsPCPButton
                    data-providerid={providerId}
                    data-testid="choose-new-pcp-button"
                    onClick={
                      locationsNotTerminated
                        ? handleChoosePCPLocationClick
                        : handleChoosePCPClick
                    }
                    size="$sm"
                    variant="outline"
                  >
                    {!locationsNotTerminated
                      ? t('PRIMARY_CARE_PROVIDER.CHOOSE_NEW_PCP')
                      : t('PRIMARY_CARE_PROVIDER.VERIFY_PCP_LOCATION')}
                  </ChooseAsPCPButton>
                </>
              ) : (
                `${t(
                  'PCP_TERMINATION.THIS_PROVIDER_MAY_BE_LEAVING_THE_NETWORK_ON'
                )} (${formattedEndDate})`
              )
            }
            variant="warning"
          />
        </TerminationWrapper>
      )
    );
  };

  return (
    <Container data-auto-testid="provider-details-container">
      {displayAlert && !(isMapView && mobileScreen) && (
        <ContentWrapper>
          <Alert
            onClose={() => {
              setDisplayAlert(false);
            }}
            title={t('LOCATION_IS_UPDATED')}
            variant="success"
          />
        </ContentWrapper>
      )}
      {!!selectedLocation && renderTerminationBanner(selectedLocation)}
      {displayInNetworkBadge && displayInNetworkBadgeEnabled && (
        <Alert
          css={AlertStyling}
          data-testid="in-network-banner"
          title={`${inNetworkBanner?.message} ${formatProviderStartDate(
            selectedLocation?.network?.start
          )}.`}
          variant="info"
        />
      )}
      <Layout.Stack
        css={{
          'abyss-layout-stack': {
            margin: '0px',
          },
        }}
        grow
        space={0}
      >
        <TabsContainer
          active={active}
          className="hide-scrollbar"
          cssProps={{ isMapView }}
          grow={false}
          key="provider-detail-tabs-list"
          onTabChange={handleTabChange}
          title={t('PROVIDER_DETAILS.TAB_TITLE')}
          variant="line"
        >
          {renderProviderTabs}
          {renderFacilityTabs}
          {renderProviderGroupTabs}
        </TabsContainer>
      </Layout.Stack>
    </Container>
  );
};
